@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-list-item {
  list-style-type: disc !important;
  margin-left: 32px;
  /* Add additional styling if needed */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #0968b3;
  animation: spin 1s ease infinite;
}

/* Base styles */
.bg-light-gray {
  background-color: #f9fafb; /* Lighter background for contrast */
}

/* Button styles */
.btn-blue {
  background-color: #0968b3; /* Blue background */
  color: #ffffff; /* White text */
  border-color: #0968b3; /* Blue border */
}

.btn-blue:hover {
  background-color: #084c7c; /* Darker blue on hover */
  border-color: #084c7c; /* Darker blue border on hover */
}

/* Header styles */
.text-blue {
  color: #0968b3; /* Blue for text */
}

/* Text and link styles */
.link-orange {
  color: #f17022; /* Orange for links */
}

.link-orange:hover {
  color: #c14a1d; /* Darker orange on hover */
}

/* Specific section backgrounds or highlights */
.bg-yellow {
  background-color: #ffd400; /* Yellow background */
  padding: 15px;
  border-radius: 8px;
}

.border-yellow {
  border:solid;
  border-color: #ffd400; /* Yellow background */
  padding: 15px;
  border-radius: 8px;
}

.bg-green {
  background-color: #2ba44c; /* Green background */
  padding: 15px;
  border-radius: 8px;
}

/* Utility classes for icons */
.icon-orange {
  color: #f17022; /* Orange for icons */
}

.social-icon-green {
  color: #2ba44c; /* Green for social media icons */
}

/* Divider style */
.divider-light {
  border-top-color: #d1d5db; /* Lighter grey for dividers */
}

/* Strong text in paragraphs */
.text-strong-orange {
  color: #f17022; /* Orange for strong text */
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

